@import 'fonts.css';

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: GT Flexa, system-ui;
  /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/

:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/

[hidden] {
  display: none;
}

.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1380px){
  .container{
    max-width: 1380px;
  }
}

h1{
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
}

h2{
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 600;
}

h3{
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
}

h4{
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

a, button, input, text-area{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

section, .section{
  overflow: hidden;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (min-width: 768px){
  section, .section{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

label{
  margin-bottom: 0.5rem;
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.nav-link{
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(49 48 47 / var(--tw-text-opacity));
}

.nav-link:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 242 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(24 24 23 / var(--tw-text-opacity));
}

.button{
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(0 91 102 / var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.button:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(0 82 92 / var(--tw-bg-opacity));
}

.button-light{
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 242 / var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(170 169 163 / var(--tw-text-opacity));
}

.button-light:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(219 217 210 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(122 121 117 / var(--tw-text-opacity));
}

.button-light.active{
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.container{
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.dropdown-item{
  display: block;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.dropdown-item:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.button-icon{
  height: 1.25rem;
  width: 1.25rem;
}

.icon{
  height: 1rem;
  width: 1rem;
}

.card{
  overflow: hidden;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.loading{
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, rgba(24,24,23,.03) 8%, rgba(24,24,23,.07) 38%, rgba(24,24,23,.03) 54%);
  background-size: 1000px 640px;
  position: relative;
}

.\!loading{
  -webkit-animation-duration: 1.8s !important;
          animation-duration: 1.8s !important;
  -webkit-animation-fill-mode: forwards !important;
          animation-fill-mode: forwards !important;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
  -webkit-animation-name: placeHolderShimmer !important;
          animation-name: placeHolderShimmer !important;
  -webkit-animation-timing-function: linear !important;
          animation-timing-function: linear !important;
  background: #f6f7f8 !important;
  background: linear-gradient(to right, rgba(24,24,23,.03) 8%, rgba(24,24,23,.07) 38%, rgba(24,24,23,.03) 54%) !important;
  background-size: 1000px 640px !important;
  position: relative !important;
}

.chat-message{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.chat-message.right{
  flex-direction: row-reverse;
}

.chat-message-text{
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(0 73 82 / var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.chat-message.right .chat-message-text{
  --tw-bg-opacity: 1;
  background-color: rgb(243 241 233 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(24 24 23 / var(--tw-text-opacity));
}

.screen-height{
  height: calc(100vh - 70px);
}

@-webkit-keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }

  100%{
    background-position: 468px 0
  }
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }

  100%{
    background-position: 468px 0
  }
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.visible{
  visibility: visible;
}

.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.inset-y-0{
  top: 0px;
  bottom: 0px;
}

.right-0{
  right: 0px;
}

.-right-1{
  right: -0.25rem;
}

.-top-1{
  top: -0.25rem;
}

.bottom-4{
  bottom: 1rem;
}

.right-4{
  right: 1rem;
}

.left-0{
  left: 0px;
}

.top-2{
  top: 0.5rem;
}

.right-2{
  right: 0.5rem;
}

.z-50{
  z-index: 50;
}

.z-10{
  z-index: 10;
}

.col-span-2{
  grid-column: span 2 / span 2;
}

.col-span-6{
  grid-column: span 6 / span 6;
}

.mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.mb-1{
  margin-bottom: 0.25rem;
}

.ml-3{
  margin-left: 0.75rem;
}

.mt-2{
  margin-top: 0.5rem;
}

.mb-6{
  margin-bottom: 1.5rem;
}

.mb-3{
  margin-bottom: 0.75rem;
}

.mb-2{
  margin-bottom: 0.5rem;
}

.mr-2{
  margin-right: 0.5rem;
}

.mb-4{
  margin-bottom: 1rem;
}

.ml-1{
  margin-left: 0.25rem;
}

.mt-1{
  margin-top: 0.25rem;
}

.block{
  display: block;
}

.inline{
  display: inline;
}

.flex{
  display: flex;
}

.inline-flex{
  display: inline-flex;
}

.grid{
  display: grid;
}

.hidden{
  display: none;
}

.h-6{
  height: 1.5rem;
}

.h-2{
  height: 0.5rem;
}

.h-10{
  height: 2.5rem;
}

.h-16{
  height: 4rem;
}

.h-8{
  height: 2rem;
}

.h-7{
  height: 1.75rem;
}

.h-12{
  height: 3rem;
}

.h-80{
  height: 20rem;
}

.h-4{
  height: 1rem;
}

.h-5{
  height: 1.25rem;
}

.h-24{
  height: 6rem;
}

.h-20{
  height: 5rem;
}

.h-0{
  height: 0px;
}

.h-32{
  height: 8rem;
}

.min-h-min{
  min-height: -webkit-min-content;
  min-height: min-content;
}

.w-full{
  width: 100%;
}

.w-6{
  width: 1.5rem;
}

.w-2{
  width: 0.5rem;
}

.w-48{
  width: 12rem;
}

.w-10{
  width: 2.5rem;
}

.w-auto{
  width: auto;
}

.w-12{
  width: 3rem;
}

.w-1{
  width: 0.25rem;
}

.w-3\/4{
  width: 75%;
}

.w-5{
  width: 1.25rem;
}

.w-4{
  width: 1rem;
}

.w-24{
  width: 6rem;
}

.w-20{
  width: 5rem;
}

.w-16{
  width: 4rem;
}

.w-32{
  width: 8rem;
}

.max-w-2xl{
  max-width: 42rem;
}

.max-w-lg{
  max-width: 32rem;
}

.max-w-xl{
  max-width: 36rem;
}

.max-w-screen-lg{
  max-width: 1024px;
}

.flex-1{
  flex: 1 1 0%;
}

.flex-shrink-0{
  flex-shrink: 0;
}

.grow{
  flex-grow: 1;
}

.origin-top-right{
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform{
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row{
  flex-direction: row;
}

.flex-col{
  flex-direction: column;
}

.flex-wrap{
  flex-wrap: wrap;
}

.items-start{
  align-items: flex-start;
}

.items-center{
  align-items: center;
}

.justify-start{
  justify-content: flex-start;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.gap-3{
  gap: 0.75rem;
}

.gap-4{
  gap: 1rem;
}

.gap-2{
  gap: 0.5rem;
}

.gap-1{
  gap: 0.25rem;
}

.gap-6{
  gap: 1.5rem;
}

.gap-0{
  gap: 0px;
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden{
  overflow: hidden;
}

.overflow-y-scroll{
  overflow-y: scroll;
}

.rounded-lg{
  border-radius: 0.5rem;
}

.rounded-full{
  border-radius: 9999px;
}

.rounded-md{
  border-radius: 0.375rem;
}

.rounded{
  border-radius: 0.25rem;
}

.rounded-sm{
  border-radius: 0.125rem;
}

.rounded-3xl{
  border-radius: 1.5rem;
}

.border-2{
  border-width: 2px;
}

.border-b-2{
  border-bottom-width: 2px;
}

.border-t-2{
  border-top-width: 2px;
}

.border-r-2{
  border-right-width: 2px;
}

.border-solid{
  border-style: solid;
}

.border-slate-200{
  --tw-border-opacity: 1;
  border-color: rgb(243 241 233 / var(--tw-border-opacity));
}

.border-b-slate-200{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 241 233 / var(--tw-border-opacity));
}

.bg-slate-200{
  --tw-bg-opacity: 1;
  background-color: rgb(243 241 233 / var(--tw-bg-opacity));
}

.bg-orange-400{
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-slate-900{
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 23 / var(--tw-bg-opacity));
}

.bg-slate-100{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 242 / var(--tw-bg-opacity));
}

.bg-slate-800{
  --tw-bg-opacity: 1;
  background-color: rgb(49 48 47 / var(--tw-bg-opacity));
}

.p-3{
  padding: 0.75rem;
}

.p-4{
  padding: 1rem;
}

.p-2{
  padding: 0.5rem;
}

.p-0{
  padding: 0px;
}

.p-1{
  padding: 0.25rem;
}

.py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-0{
  padding-left: 0px;
  padding-right: 0px;
}

.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0{
  padding-top: 0px;
  padding-bottom: 0px;
}

.pr-2{
  padding-right: 0.5rem;
}

.pr-0{
  padding-right: 0px;
}

.pb-4{
  padding-bottom: 1rem;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-xl{
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl{
  font-size: 3.75rem;
  line-height: 1;
}

.text-2xl{
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-normal{
  font-weight: 400;
}

.text-slate-700{
  --tw-text-opacity: 1;
  color: rgb(97 96 93 / var(--tw-text-opacity));
}

.text-slate-600{
  --tw-text-opacity: 1;
  color: rgb(122 121 117 / var(--tw-text-opacity));
}

.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-slate-900{
  --tw-text-opacity: 1;
  color: rgb(24 24 23 / var(--tw-text-opacity));
}

.text-slate-800{
  --tw-text-opacity: 1;
  color: rgb(49 48 47 / var(--tw-text-opacity));
}

.text-slate-400{
  --tw-text-opacity: 1;
  color: rgb(170 169 163 / var(--tw-text-opacity));
}

.text-slate-500{
  --tw-text-opacity: 1;
  color: rgb(146 145 140 / var(--tw-text-opacity));
}

.opacity-0{
  opacity: 0;
}

.opacity-100{
  opacity: 1;
}

.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-opacity-5{
  --tw-ring-opacity: 0.05;
}

.filter{
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition{
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100{
  transition-duration: 100ms;
}

.duration-75{
  transition-duration: 75ms;
}

.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.hover\:bg-slate-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 241 233 / var(--tw-bg-opacity));
}

.hover\:bg-slate-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 242 / var(--tw-bg-opacity));
}

.hover\:text-slate-900:hover{
  --tw-text-opacity: 1;
  color: rgb(24 24 23 / var(--tw-text-opacity));
}

.focus\:outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-white:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus{
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-slate-200:focus{
  --tw-ring-offset-color: #f3f1e9;
}

.group:hover .group-hover\:bg-slate-400{
  --tw-bg-opacity: 1;
  background-color: rgb(170 169 163 / var(--tw-bg-opacity));
}

@media (min-width: 640px){
  .sm\:static{
    position: static;
  }

  .sm\:inset-auto{
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:ml-6{
    margin-left: 1.5rem;
  }

  .sm\:block{
    display: block;
  }

  .sm\:hidden{
    display: none;
  }

  .sm\:items-stretch{
    align-items: stretch;
  }

  .sm\:justify-start{
    justify-content: flex-start;
  }

  .sm\:pr-0{
    padding-right: 0px;
  }
}

@media (min-width: 768px){
  .md\:block{
    display: block;
  }

  .md\:flex{
    display: flex;
  }

  .md\:hidden{
    display: none;
  }

  .md\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:p-8{
    padding: 2rem;
  }

  .md\:p-6{
    padding: 1.5rem;
  }

  .md\:py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:text-4xl{
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl{
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1024px){
  .lg\:block{
    display: block;
  }

  .lg\:hidden{
    display: none;
  }

  .lg\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:p-6{
    padding: 1.5rem;
  }
}

